import React, { Component } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../css/tables.scss';
import '../css/default-page.scss';

const showdown  = require('showdown');
const contentful = require('contentful');
const client = contentful.createClient({
  space: 'tcbspyj33u84',
  accessToken: 'xc_8OxyC5nMrVN_6JoklnQLWwimGu8T23QhmR2ZBBIg'
})


class Prices extends Component {
  constructor() {
    super();

    this.state = {
      title: "",
      subtitle: "",
      pakketten: {},
      overig: {},
      voorwaarden: ""
    };
    let converter = new showdown.Converter();
    let _self = this;

    client.getEntry('ecEd6zp2kVLh7BfbmVmRI').then((entry) =>
      _self.setState({
        title: entry.fields.title,
        subtitle: entry.fields.subtitle,
        pakketten: entry.fields.pakketten,
        overig: entry.fields.overig,
        voorwaarden: converter.makeHtml(entry.fields.voorwaarden)
      })
    );
  }

  renderTable(table, classes){
    return (table && table.titels ?
      <table className={classes}>
        <thead><tr>
          {table.titels.map(function(item, i){
            return <th key={i}>{item}</th>
          })}
        </tr></thead>
        <tbody>
          {table.content.map(function(item, i){
            return <tr key={i}>
              {item.map(function(str, y){
                return y == 0 ? <th key={y}>{str}</th> : <td key={y}>{str}</td>
              })}
            </tr>
          })}
        </tbody>
      </table> : null
    );
  }

  render() {
    return (
      <div className="prices App">
        <Header />
        <div className="container">
          <div className="col-xs-12">
            <h1>{this.state.title}</h1>
            <p className="duration">
            {this.state.subtitle}
            </p>

            {this.renderTable(this.state.pakketten, "price-table")}

            {this.renderTable(this.state.overig, "price-table middle")}

          <div dangerouslySetInnerHTML={{__html: this.state.voorwaarden}}></div>
          </div>

        </div>
        <Footer />
      </div>
    );
  }
}

export default Prices;
