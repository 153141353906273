import React, { Component } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Bovag from '../components/Bovag';
import '../css/error.scss';

class Error404 extends Component {
  render() {
    return (
      <div className="Error404 App">
        <Header />
        <h1>404 - pagina niet gevonden</h1>
        <Footer />
      </div>
    );
  }
}

export default Error404;
