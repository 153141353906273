import React, { Component } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Bovag from '../components/Bovag';
import '../css/home.scss';

const showdown  = require('showdown');
const contentful = require('contentful');
const client = contentful.createClient({
  space: 'tcbspyj33u84',
  accessToken: 'xc_8OxyC5nMrVN_6JoklnQLWwimGu8T23QhmR2ZBBIg'
})


class Home extends Component {
  constructor() {
    super();

    this.state = {
      title: "",
      content: "",
      about: ""
    };
    let converter = new showdown.Converter();
    let _self = this;

    client.getEntry('6Xmkx2llCPTH6HsFWCPaRH').then((entry) =>
      _self.setState({
        title: entry.fields.title,
        content: converter.makeHtml(entry.fields.content),
        about: converter.makeHtml(entry.fields.overToine)
      })
    );
  }

  render() {
    return (
      <div className="home App">
        <Header />
        <div className="heading-image">
          <img className="hidden-xs" src={require("../assets/img/heading-img.jpg")} />
        </div>


        <div className="container">
          <div className="col-xs-12" >
            <h1>{this.state.title}</h1>
            <div dangerouslySetInnerHTML={{__html: this.state.content}}>
            </div>
          </div>
        </div>
        <div className="over-toine container">
        <div className="col-sm-6 col-md-4 col-xs-12 image-container">
          <img src={require("../assets/img/toine.png")} />
        </div>
          <div className="col-sm-6 col-md-8 col-xs-12">
            <blockquote>
              <div dangerouslySetInnerHTML={{__html: this.state.about}}></div>
              <span className="author">Toine Vermeulen</span>
            </blockquote>
          </div>

        </div>
        <Footer />
      </div>
    );
  }
}

export default Home;
