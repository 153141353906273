import React, { Component } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../css/default-page.scss';
import '../css/tables.scss';

const showdown  = require('showdown');
const contentful = require('contentful');
const client = contentful.createClient({
  space: 'tcbspyj33u84',
  accessToken: 'xc_8OxyC5nMrVN_6JoklnQLWwimGu8T23QhmR2ZBBIg'
})


class Theorie extends Component {

  constructor() {
    super();

    this.state = {
      title: "",
      content: ""
    };
    let converter = new showdown.Converter();
    let _self = this;

    client.getEntry('6wamtD5qLv10rXAZ528bXv').then((entry) =>
      _self.setState({
        title: entry.fields.title,
        content: converter.makeHtml(entry.fields.content)
      })
    );
  }

  render() {
    return (
      <div className="default-page App">
        <Header />
        <div className="container">
          <div className="col-xs-12 col-md-8">
            <h1>{this.state.title}</h1>
            <div class="content" dangerouslySetInnerHTML={{__html: this.state.content}}></div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Theorie;
