import React, { Component } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../css/default-page.scss';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const showdown  = require('showdown');
const contentful = require('contentful');
const client = contentful.createClient({
  space: 'tcbspyj33u84',
  accessToken: 'xc_8OxyC5nMrVN_6JoklnQLWwimGu8T23QhmR2ZBBIg'
})

const StyledButton = withStyles({
  root: {
    background: '#2688ba',
    borderRadius: 5,
    border: 0,
    color: 'white',
    padding: '5px 20px',
    fontSize:'14px',
    margin: '10px 0 10px 0',
    '&:hover': {
      background: '#EC8F1B',
      color:"#FFFFFF"
    }
  }
})(Button);


class Machtiging extends Component {
  constructor() {
    super();

    this.state = {
      title: "",
      content: ""
    };
    let converter = new showdown.Converter();
    let _self = this;

    client.getEntry('4nZi7pN36HmEUnkgg8AUYn').then((entry) =>
      _self.setState({
        title: entry.fields.title,
        content: converter.makeHtml(entry.fields.content)
      })
    );
  }
  render() {
    return (
      <div className="default-page App">
        <Header />
        <div className="container">
          <div className="col-xs-12 col-md-8">
            <h1>{this.state.title}</h1>
            <div class="content" dangerouslySetInnerHTML={{__html: this.state.content}}></div>
            <StyledButton variant="contained" color="primary">
              <a href="https://www.cbr.nl/nl/mijncbr.htm">Direct Inloggen</a>
          </StyledButton>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Machtiging;
