import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Link, BrowserRouter as Router, Switch } from 'react-router-dom';
import './index.css';
import Home from './pages/Home';
import Prices from './pages/Prices';
import Subscribe from './pages/Subscribe';
import Error404 from './pages/Error404';
import Privacy from './pages/Privacy';

import Machtiging from './pages/Machtiging';
import Gezondheidsverklaring from './pages/Gezondheidsverklaring';
import Itheorie from './pages/Itheorie';
import Praktijk from './pages/Praktijk';
import Theorie from './pages/Theorie';

import Faq from './pages/FAQ';
import News from './pages/News';


import * as serviceWorker from './serviceWorker';

const routing = (
  <Router>
    <div>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/tarieven" component={Prices} />
        <Route path="/aanmelden" component={Subscribe} />
        <Route path="/privacy-statement" component={Privacy} />

        <Route path="/machtiging" component={Machtiging} />
        <Route path="/i-theorie" component={Itheorie} />
        <Route path="/gezondheidsverklaring" component={Gezondheidsverklaring} />
        <Route path="/theorie-examen" component={Theorie} />
        <Route path="/praktijk-examen" component={Praktijk} />

        <Route path="/nieuws" component={News} />
        <Route path="/Faq" component={Faq} />
        <Route component={Error404} />
      </Switch>
    </div>
  </Router>
);

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
