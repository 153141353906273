import React, { Component } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../css/subscribe.scss';
import { Phone } from 'react-feather';
import { Mail } from 'react-feather';
import { MessageSquare } from 'react-feather';
import { Facebook } from 'react-feather';

const showdown  = require('showdown');
const contentful = require('contentful');
const client = contentful.createClient({
  space: 'tcbspyj33u84',
  accessToken: 'xc_8OxyC5nMrVN_6JoklnQLWwimGu8T23QhmR2ZBBIg'
})

class Subscribe extends Component {
  constructor() {
    super();

    this.state = {
      title: "",
      content: ""
    };
    let converter = new showdown.Converter();
    let _self = this;

    client.getEntry('T1bfyjNWHOqYwa37BkgXJ').then((entry) =>
      _self.setState({
        title: entry.fields.title,
        content: converter.makeHtml(entry.fields.content)
      })
    );
  }

  render() {
    return (
      <div className="subscribe App">
        <Header />
        <div className="container">
          <div className="col-xs-12 col-lg-8">
          <h1>{this.state.title}</h1>
          <div class="content" dangerouslySetInnerHTML={{__html: this.state.content}}></div>

            <h3>Verder zijn wij ook bereikbaar op:</h3>
            <ul className="with-icons">
              <li><a href="https://wa.me/31681161429"><MessageSquare />bereik ons via Whatsapp</a></li>
            </ul>


          </div>
          <div className="hidden-xs col-lg-4 contact-info">
            <h3>Contact</h3>
            <ul className="with-icons">
              <li className="tel"><a href="tel:+31681161429"><Phone />06-81161429</a></li>
              <li className="mail"><a href="mailto:info@rijschool-vermeulen.nl"><Mail />info@rijschool-vermeulen.nl</a></li>
            </ul>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Subscribe;
