import React, { Component } from 'react';
import '../css/footer.scss';
import { Phone } from 'react-feather';
import { Mail } from 'react-feather';
import Bovag from './Bovag';

class Footer extends Component {

  render() {
    return(
      <div className="footer">
        <div className="top">
          <div className="container">
            <div className="col-xs-12 footer-container">
              <div className="contact-info">
                <h3>Contact</h3>
                <ul>
                  <li className="tel"><a href="tel:+31681161429"><Phone />06-81161429</a></li>
                  <li className="mail"><a href="mailto:info@rijschool-vermeulen.nl"><Mail />info@rijschool-vermeulen.nl</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="container">
            <div className="col-xs-12">
              © copyright 2022 Rijschool Vermeulen | <a href="/privacy-statement">privacy statement</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
