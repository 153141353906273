import React, { Component } from 'react';
import '../css/header.scss';
import { Phone } from 'react-feather';
import { Mail } from 'react-feather';
import Navigation from './Navigation';

class Header extends Component {

  render() {
    return(
      <div>
        <div className="header">
          <div className="top">
            <div className="container">
              <div className="col-xs-12">
                <ul className="hidden-xs">
                  <li><a href="tel:0681161429"><Phone />06-81161429</a></li>
                  <li><a href="mailto:info@rijschool-vermeulen.nl"><Mail />info@rijschool-vermeulen.nl</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="bottom">
            <div className="container">
              <div className="col-xs-12 logo-container">
                <a href="/" className="logo">
                  <span>Rijschool</span>
                  <span>Vermeulen</span>
                </a>
              </div>
            </div>
          </div>
          <Navigation />
        </div>
        <div className="header-filler visible-xs"></div>
      </div>
    );
  }
}

export default Header;
