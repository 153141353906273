import React, { Component } from 'react';
import '../css/bovag.scss';

class Bovag extends Component {
  constructor(){
    super();

    this.state = {
      active: false
    }
  }
  componentDidMount() {
    this.setState({
      active: this.props.active
    });

  }
  render() {
    let classes = this.props.blacked ? "black-border bovag-logo" : "bovag-logo";
    let staticClass = this.props.static ? "about-bovag static" : "about-bovag"
    let description = this.state.active ? <div className="description">
      Voordelen van een rijschool aangesloten bij de BOVAG:
      <ul>
        <li>Werken met een lesovereenkomst</li>
        <li>Garantiefonds</li>
        <li>Betrouwbaar</li>
        <li>Kwaliteit</li>
        <li>Hoog slagingspercentage</li>
      </ul>
    </div> : "";


    return(
      <div id={this.props.id} className={staticClass}>
        <div className={classes} onClick={ () => !this.props.static && this.setState({active: !this.state.active}) }>
          <img src={require("../assets/img/BOVAG_logo.jpg")} />
        </div>
        {description}
      </div>
    );
  }
}

export default Bovag;
