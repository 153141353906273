import React, { Component } from 'react';
import '../css/navigation.scss';
import { Menu, ArrowRight } from 'react-feather';


class Navigation extends Component {
  constructor(props){
    super(props);
    this.state={
      menuOpen:false,
    }
  }

  handleMenuClick() {
    this.setState({menuOpen:!this.state.menuOpen});
  }
  render() {
    return(
      <div className="navigation">
        <div className="container">
          <div className="menu-button" onClick={()=>this.handleMenuClick()} >
            <Menu />
          </div>
          <ul className={this.state.menuOpen ? "menu open" : "menu closed"}>
            <li><a href="/">Home</a></li>
            <li><a href="/tarieven">Tarieven</a></li>
            <li>
              <span>Rijbewijs</span>
              <ul>
                <li><a href="/machtiging"><span>Machtiging</span>  <ArrowRight /></a></li>
                <li><a href="/gezondheidsverklaring"><span>Gezondheidsverklaring</span> <ArrowRight /></a></li>
                <li><a href="/theorie-examen"><span>Theorie examen</span> <ArrowRight /></a></li>
                <li><a href="/i-theorie"><span>Leren via I-theorie</span> <ArrowRight /></a></li>
                <li><a href="/praktijk-examen"><span>Praktijk examen</span> <ArrowRight /></a></li>

              </ul>
            </li>
            <li>
              <span>Meer</span>
              <ul>
                <li><a href="/nieuws"><span>Nieuws</span>  <ArrowRight /></a></li>
                <li><a href="/faq"><span>FAQ</span> <ArrowRight /></a></li>
              </ul>
            </li>
            <li><a href="/aanmelden">Meld je aan!</a></li>
          </ul>

        </div>
      </div>
    );
  }
}

export default Navigation;
